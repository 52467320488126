<template>
  <div>
    <CContainer fluid>
      <IkoCartas
          :cartas="cartas"
          name="category"
          :loading="loading"
          @deleteCarta="eliminarCarta"
          @criarModal="criarModal"
          @editarModal="editarModal"
      ></IkoCartas>
    </CContainer>

    <CModal
        :title="`${modal.type} Category`"
        :color="modal.type === 'Edit' ? 'warning' : 'success'"
        :show.sync="modal.open"
    >
      <CCol sm="12">
        <CInput
            v-model="form.name"
            label="Name"
            placeholder="Category Name"
        />
      </CCol>

      <CCol sm="12">
        <CInput
            v-model="form.displayName"
            label="Display Name"
            placeholder="Category Display Name"
        />
      </CCol>

      <CCol sm="12">
        <CInput
            v-model="form.description"
            label="Description"
            placeholder="Category Description"
        />
      </CCol>

      <template #footer>
        <CButton @click="modal.open = false" color="danger">Cancel</CButton>
        <CButton @click="modalSubmit" :disabled="aSubmeter" color="success">Submit</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { getCategory, postSubCategory, deleteCategory, putCategory } from '@/api/categories';
import IkoCartas from '@/components/IkoCartas.vue';
export default {
  name: 'Category',
  components: {
    IkoCartas,
  },
  data: () => ({
    modal: {
      type: 'Edit',
      open: false
    },
    form: {},
    aSubmeter: false,
    loading: true,
    cartas: []
  }),
  methods: {
    addCarta(carta) {
      this.cartas.push({
        data: carta,
        header: carta.displayName,
        text: `<b>Name<b>: ${carta.name}<br/><b>Description</b>: ${carta.description ?? 'none'}<br/><b>Children</b>: ${(carta.children ?? []).length}`,
        to: [{ display: 'Products', name: 'CategoryProducts', params: { category: carta.name }}, {display: 'Children', name: 'CategoryChildren', params: { category: carta.name}}]
      });
    },
    modalSubmit() {
      this.aSubmeter = true;
      if(this.modal.type === 'Edit') {
        putCategory(this.form.name, this.form).then(() => {
          this.carregarCartas();
          this.form = {};
          this.modal.open = false;
          this.aSubmeter = false;
        }).then(() => this.aSubmeter = false);
      } else {
        postSubCategory(this.$route.params.category, this.form).then(() => {
          this.addCarta(this.form);
          this.form = {};
          this.modal.open = false;
          this.aSubmeter = false;
        }).then(() => this.aSubmeter = false);
      }
    },
    editarModal(idx) {
      this.form = {...this.cartas[idx].data};
      this.modal.type = 'Edit';
      this.modal.open = true;
    },
    criarModal() {
      this.form = {};
      this.modal.type = 'New';
      this.modal.open = true;
    },
    eliminarCarta(idx) {
      deleteCategory(this.cartas[idx].data.name).then(() => {
        this.cartas.splice(idx, 1);
        this.aSubmeter = false;
        this.eliminarModal = false;
      });
    },
    carregarCartas() {
      this.loading = true;
      this.cartas = [];
      getCategory(this.$route.params.category, ['products', 'children', 'childrenChildren']).then(({ children }) => {
        console.log(children)
        children = children.sort((a, b) => a.displayName - b.displayName);
        children.forEach(loc => this.addCarta(loc));
        this.loading = false;
      }).catch(() => this.loading = false);
    }
  },
  mounted() {
    this.carregarCartas();
  }
}
</script>