import Vue from 'vue';
import { CAlert } from "@coreui/vue";

export default (message) => {
    const alert = Vue.extend({
      components: { CAlert },
      template: `<div id="alertError">
        <CAlert color="danger" :fade="true" :closeButton="true" :show="true">
          ${message}
        </CAlert>
      </div>`
    });
    new alert().$mount('#alertError');
}