import 'core-js/stable';
import 'babel-polyfill';
import Vue from 'vue';
import App from './App';
import router from './router';
import CoreuiVue from '@coreui/vue';
import firebase from 'firebase/app';
import { iconsSet as icons } from './assets/icons/icons.js';
import store from './store/';

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

var firebaseConfig = {
  apiKey: "AIzaSyBlllw7dLWttnxcWw1yLvkDcBRu25hTuBM",
  authDomain: "swiper-71ea2.firebaseapp.com",
  databaseURL: "https://swiper-71ea2.firebaseio.com",
  projectId: "swiper-71ea2",
  storageBucket: "swiper-71ea2.appspot.com",
  messagingSenderId: "436846379250",
  appId: "1:436846379250:web:c58b205747bfd78973906b",
  measurementId: "G-HS13E9K5HQ"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
