import request from '@/utils/request';

export function getUser(id = '@me') {
  return request({
    url: `/users/${id}`,
    method: 'get'
  });
}

export function getUsers() {
  return request({
    url: '/users',
    method: 'get'
  })
}

export function putUser(id, data) {
  return request({
    url: `/users/${id}`,
    method: 'put',
    data
  })
}

export function deleteUser(id) {
  return request({
    url: `/users/${id}`,
    method: 'delete'
  });
}