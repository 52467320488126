import request from '@/utils/request';

export function getCategories(options) {
  return request({
    url: `/categories${options !== undefined ? `?options${options.length ? `=${options.join(encodeURIComponent('+'))}` : ''}` : ''}`,
    method: 'get',
  });
}

export function getCategory(name, options) {
  return request({
    url: `/categories/${name}${options !== undefined ? `?options${options.length ? `=${options.join(encodeURIComponent('+'))}` : ''}` : ''}`,
    method: 'get',
  });
}

export function postSubCategory(name, data) {
  return request({
    url: `/categories/${name}`,
    method: 'post',
    data
  });
}

export function postCategory(data) {
  return request({
    url: '/categories',
    method: 'post',
    data
  });
}

export function putCategory(id, data) {
  return request({
    url: `/categories/${id}`,
    method: 'put',
    data
  })
}

export function deleteCategory(id) {
  return request({
    url: `/categories/${id}`,
    method: 'delete',
  })
}