import Cookies from 'js-cookie';
import firebase from 'firebase';
import store from '@/store'

export function getCookie(key) {
  return Cookies.get(key);
}

export function setCookie(key, value) {
  return Cookies.set(key, value, { expires: 1 });
}

export function removeCookie(key) {
  return Cookies.remove(key);
}

const provider = new firebase.auth.GoogleAuthProvider();
export async function signIn() {
  try {
    const { user } = await firebase.auth().signInWithPopup(provider);
    const token = await user.getIdToken();
    await store.dispatch('user/login', { photoURL: user.photoURL, token});
    await store.dispatch('user/info');
  } catch (err) {
    console.error(err);
    throw err;
  }
}