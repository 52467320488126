<template>
    <div>
        <CRow align-vertical="center" style="margin-bottom: 15px;" v-show="!loading">
            <CCol>
                <p><b>{{ cartas.length }}</b> {{ cartas.length ? name : `${name}s` }}.</p>
            </CCol>
            <CCol :sm="{ offset: 3 }" v-show="!hideCriar">
                <CButton @click="$emit('criarModal')" color="primary">
                    +
                </CButton>
            </CCol>
        </CRow>
        <CRow align-horizontal="center">
            <CSpinner v-show="loading"></CSpinner>
            <CCol v-show="!loading" lg="4" sm="6" v-for="(carta, i) in cartas" v-bind:key="i">
                <CCard>
                    <CCardHeader>
                    <CRow align-vertical="center">
                        <CCol sm="8">
                          <span v-html="carta.header"></span>
                        </CCol> 
                        <CCol sm="4">
                            <CRow align-horizontal="center">
                                <CButton v-show="!hideEdit" style="margin-right:5px;" color="warning" @click="$emit('editarModal', i)"><CIcon name="cil-pencil"></CIcon></CButton>
                                <CButton v-show="!hideDelete" color="danger" @click="confirmarEliminar({idx: i, nome: carta.header })"><CIcon name="cil-trash"></CIcon></CButton>
                            </CRow>
                        </CCol>
                    </Crow>
                    </CCardHeader>
                    <CCardBody>
                    <p v-if="carta.text"><span v-html="carta.text"></span><br/></p>
                    <CButton color="primary" style="margin-right: 5px;" v-for="(para, i) in carta.to || []" v-bind:key="i" :to="{ name: para.name, params: para.params }">{{ para.display }}</CButton>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>

        <CModal
            :title="`Delete ${name}`"
            color="danger"
            :show.sync="eliminar.modal">

            <p>Please confirm you want to delete the {{ name }} <b>{{ eliminar.form.nome }}</b>.
            <br/>This will cascade and permanently delete all data related to {{ name }}.</p>
        <template #footer>
            <CButton @click="eliminar.modal = false" color="danger">Cancel</CButton>
            <CButton @click="deleteCarta" :disabled="aSubmeter" color="success">Submit</CButton>
        </template>
        </CModal>       
    </div>
</template>

<script>
export default {
    name: 'IkoCartas',
    props: {
        cartas: Array,
        name: String,
        namePlural: String,
        loading: Boolean,
        hideCriar: Boolean,
        hideDelete: Boolean,
        hideEdit: Boolean,
    },
    data: () => ({
        aSubmeter: false,
        eliminar: {
            form: {},
            modal: false
        },
    }),
    methods: {
        confirmarEliminar(form) {
            this.eliminar.form = form;
            this.eliminar.modal = true;
        },
        deleteCarta() {
            this.aSubmeter = true;
            this.$emit('deleteCarta', this.eliminar.form.idx);
            setTimeout(() => {
                this.eliminar.modal = false
                this.aSubmeter = false;
            }, 1000);
        }
    }
}
</script>