import { getCookie, setCookie } from '@/utils/auth';
import { getUser } from '../../api/users';

const state = {
    name: '',
    email: '',
    isAdmin: false,
    token: getCookie('token'),
    photoURL: getCookie('photoURL'),
}

const mutations = {
    SET_FIREBASE_DATA(state, data) {
        state.token = data.token;
        state.photoURL = data.photoURL;
        setCookie('token', data.token);
        setCookie('photoURL', data.photoURL);
    },
    SET_DATA(state, user) {
        state.name = user.name;
        state.email = user.email;
        state.isAdmin = user.isAdmin;
    }    
}

const actions = {
    login({ commit }, {photoURL, token}) {
        commit('SET_FIREBASE_DATA', { photoURL, token});
    },
    info({ commit, state }) {
        return new Promise((resolve, reject) => {
            if (!state.token)
                reject(null);

            getUser().then((user) => {
                commit('SET_DATA', user);
                resolve(user);
            }).catch(error => reject(error));
        });
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
  