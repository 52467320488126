import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store';
import CategoryChildren from "@/views/categories/CategoryChildren";

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Categories
const Categories = () => import('@/views/categories/Categories');
const CategoryProducts = () => import('@/views/categories/Products');

// View - BulkAdd
const BulkAddProducts = () => import('@/views/bulk/AddProducts')

// Views - Brands
const Brands = () => import('@/views/brands/Brands');
const BrandProducts = () => import('@/views/brands/Products');

// Views - Props
const Props = () => import('@/views/props/Props');
const PropProducts = () => import('@/views/props/Products');

// Views - Error
const Erro404 = () => import('@/views/erro/Erro404')
const Erro500 = () => import('@/views/erro/Erro500')
const Erro503 = () => import('@/views/erro/Erro503')

// Views - Id
const Login = () => import('@/views/id/Login')

Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),

});
router.beforeEach(beforeEach);
export default router;

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/cats',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'cats',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Categories
            },
            {
              path: ':category',
              redirect: { name: 'Products' },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'prods',
                  name: 'CategoryProducts',
                  component: CategoryProducts,
                },
                {
                  path: 'children',
                  name: 'CategoryChildren',
                  component: CategoryChildren
                }
              ]
            },
          ]
        },
        {
          path: 'brands',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Brands',
              component: Brands
            },
            {
              path: ':brand',
              name: 'BrandProducts',
              component: BrandProducts
            }
          ]
        },
        {
          path: 'props',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Props',
              component: Props
            },
            {
              path: ':prop',
              name: 'PropProducts',
              component: PropProducts
            }
          ]
        },
        {
          path: 'bulk',
          redirect: '/bulk/prods',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'prods',
              name: 'BulkAddProducts',
              component: BulkAddProducts
            }
          ]
        }
      ]
    },
    {
      path: '/id',
      name: 'Login',
      component: Login,
      meta: {
        to: '/'
      }
    },
    {
      path: '/err',
      redirect: '/err/404',
      name: 'Erro',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Erro404',
          component: Erro404
        },
        {
          path: '500',
          name: 'Erro500',
          component: Erro500
        },
        {
          path: '503',
          name: 'Erro503',
          component: Erro503
        }
      ]
    },
    {
      path: '*',
      redirect: { name: 'Erro404' }
    }
  ]
}

const free = ['Login', 'Register', 'ResetPassword', 'Erro404', 'Erro500', 'Erro503']
async function beforeEach(to, from, next) {
  if (!free.includes(to.name ?? 'Home')) {
    let user = store.state.user;
    if (!user?.isAdmin) {
      try {
        user = await store.dispatch('user/info');
        if (!user || !user.isAdmin) return next({ name: 'Login', query: { redirect: to.fullPath ?? '/' } });
      // eslint-disable-next-line no-empty
      } catch {
        return next({ name: 'Login', query: { redirect: to.fullPath ?? '/' } })
      }
    }
  }

  return next();
}