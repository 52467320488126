import axios from 'axios';
import store from '@/store';
import router from '@/router';
import displayAlert from '@/utils/displayAlert'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 180000,
});

// request interceptor
service.interceptors.request.use(
  config => {
    if (store.state.user.token) config.headers.authorization = `Bearer ${store.state.user.token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    // Erro da cloudflare -> always on
    if (response.status === 512) throw new Error(); // n tem response ent é processado como 503
    return response.data;
  },
  error => {
    if (!error.response) {
      router.push({ name: 'Erro503' });
      return Promise.reject(error);
    }

    displayAlert(error.response.data.message ?? 'Erro inesperado.')

    switch (error.response.status) {
      case 401: // Unauthorized
      case 403: // Forbidden
        console.log('FORBIDDEN');
        //router.push({ name: 'Login', query: { redirect: to.fullPath ?? '/' } });
    }

    return Promise.reject(error);
  }
);

export default service;
